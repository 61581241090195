<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-subtitle>
            <v-radio-group
              v-model="tier_type"
              row
              mandatory
              dense
              v-if="editedItem.id < 0"
            >
              <v-radio
                :label="parametre <= 3 ? 'Fournisseurs' : 'Produits'"
                :value="0"
              ></v-radio>
              <v-radio label="Categorie" :value="2"></v-radio>
            </v-radio-group>
            <v-spacer> </v-spacer>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="2" md="12" v-if="tier_type == '2'">
                  <selecttree
                    :items="cat_list"
                    :nbr_chips="4"
                    v-model="editedItem.categories"
                    :label="'Categories'"
                    :dense="true"
                  >
                  </selecttree>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="parametre <= 3 && tier_type == '0'"
                >
                  <cursorselect
                    :Qsearch="tierscursor"
                    :Qresp="'tiers_cursor'"
                    :value="editedItem.tier_id"
                    :text_fields="['code_fournisseur', 'nom']"
                    :variableadd="variableadd"
                    :label="'Fournisseur'"
                    :rules="true"
                    @change="tierChange"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="parametre > 3 && tier_type == '0'"
                >
                  <cursorselect
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="editedItem.produit_id"
                    :text_fields="['code', 'label']"
                    :variableadd="variableadd_p"
                    @change="produit_change"
                    :label="'Produit'"
                    :rules="true"
                    :key="cs"
                  >
                  </cursorselect>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  v-if="parametre != 3 && tier_type == '0'"
                >
                  <v-text-field
                    autocomplete="on"
                    dense
                    v-model="editedItem.ref_ext"
                    :readonly="readonly"
                    :label="'Reference Externe'"
                    :rules="[(v) => ref_code || 'Reference Externe existe !!']"
                    :loading="libv"
                    @input="ref_change"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="9"
                  v-if="parametre != 3 && tier_type == '0'"
                >
                  <v-text-field
                    autocomplete="on"
                    dense
                    v-model="editedItem.label_ext"
                    :readonly="readonly"
                    :label="'Designation Externe'"
                    :loading="libv"
                    :rules="[(v) => lib || 'Designation Externe existe !!']"
                    @focus="$event.target.select()"
                    @input="ref_change"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="
                    (parametre == '5' || tier_type == '0') &&
                    ($store.state.auth.includes('01027') ||
                      $store.state.isadmin)
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.prixachat"
                    :readonly="readonly"
                    :label="
                      'Prix D\'achat' +
                      (editedItem.devise && editedItem.unit
                        ? '(' + editedItem.devise + '/' + editedItem.unit + ')'
                        : '')
                    "
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.delai"
                    :readonly="readonly"
                    label="Delai(jours)"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qualite"
                    :readonly="readonly"
                    :rules="[
                      (v) =>
                        !v ||
                        (editedItem.qualite <= 10 && editedItem.qualite >= 0) ||
                        'Note invalide',
                    ]"
                    label="Qualite(Note/10)"
                    required
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-alert v-model="alert" :type="type_alert" dismissible>{{
                message
              }}</v-alert>
              <v-spacer></v-spacer>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_PC from "../graphql/Product/CREATE_PRODUCT_CLIENT.gql";
import UPDATE_PC from "../graphql/Product/UPDATE_PRODUCT_CLIENT.gql";
import CREATE_PRODUCT_ARTICLE from "../graphql/Product/CREATE_PRODUCT_ARTICLE.gql";
import UPDATE_PRODUCT_ARTICLE from "../graphql/Product/UPDATE_PRODUCT_ARTICLE.gql";
import INSERT_ARTICLE_PRODUCT_CAT from "../graphql/Product/INSERT_ARTICLE_BY_PRODUCT.gql";
import INSERT_ARTICLE_TIER_CAT from "../graphql/Tier/INSERT_ARTICLE_BY_TIER.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import PRODUCTS_CURSOR from "../graphql/Commande/PRODUCTS_CURSOR.gql";
//import ALLCODE_ARTICLES from "../graphql/Product/ALLCODE_ARTICLES.gql";

export default {
  components: {
    selecttree: () => import("./SelectTree.vue"),
    confirmdialog: () => import("./ConfirmDialog.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  name: "articleform",
  props: {
    item: Object,
    items: Array,
    product: Object,
    readonly: Boolean,
    showForm: Boolean,
    parametre: { type: Number, default: 3 },
    cat_list: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 5000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
    message: "",
    lc: 0,
    cs: 100,
    libv: false,
    lib: true,
    ref_code: true,
    alert: false,
    type_alert: "info",
    tier_type: "0",
    tosell: "0",
    allarticles: [],
  }),

  computed: {
    tierscursor() {
      return TIERS_CURSOR;
    },
    variableadd() {
      let w = {
        TypeScope: 1,
        statut_id: [2, 3],
      };
      return w;
    },
    variableadd_p() {
      let w = {
        Kind: 2,
        Type: this.$store.state.products_types.map((elm) => elm.id),
        statut_id: [2],
      };
      return w;
    },
    products_cursor() {
      return PRODUCTS_CURSOR;
    },

    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  async mounted() {
    if (this.parametre == 2) {
      this.tosell = this.product.tosell;
    }
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.cs++;
    }
    if (this.product) this.editedItem.unit = this.product.unit;
  },

  methods: {
    ref_change() {
      this.$refs.form.resetValidation();
    },
    async label_valid() {
      this.lib = true;
      this.ref_code = true;
      let ok = true;
      if (this.editedItem.label_ext || this.editedItem.ref_ext) {
        this.libv = true;

        this.libv = false;
        if (this.editedItem.label_ext) {
          let i = this.items.findIndex(
            (elm) =>
              elm.id != this.editedItem.id &&
              elm.label_ext == this.editedItem.label_ext
          );
          if (i >= 0) {
            this.lib = false;
            this.$refs.form.validate();
            ok = false;
          }
        }
        if (this.editedItem.ref_ext) {
          let i = this.items.findIndex(
            (elm) =>
              elm.id != this.editedItem.id &&
              elm.ref_ext == this.editedItem.ref_ext
          );
          if (i >= 0) {
            this.ref_code = false;
            this.$refs.form.validate();
            ok = false;
          }
        }
        // }
        // }
      }
      return ok;
    },
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    tierFilter(item, queryText) {
      let textlist = [];
      let l =
        this.parametre == 1
          ? ["code_client", "nom"]
          : ["code_fournisseur", "nom"];
      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    productFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    tierChange(item, l) {
      let list = [];
      if (l) list = l;
      this.editedItem.tier_id = item;
      let i = list.findIndex((elm) => elm.id == this.editedItem.tier_id);
      if (i >= 0) {
        this.editedItem.tier_name = list[i].nom;
        this.editedItem.code_fournisseur = list[i].code_fournisseur;
        this.editedItem.devise = list[i].devise;
      }
    },
    produit_change(item, l) {
      let list = [];
      if (l) {
        list = l;
        this.editedItem.produit_id = item;
      }
      let i = list.findIndex((elm) => elm.id == this.editedItem.produit_id);
      if (i >= 0) {
        this.editedItem.product_name = list[i].label;
        this.editedItem.label = list[i].label;
        this.editedItem.code = list[i].code;
        this.editedItem.unit = list[i].unit;
      }
    },
    close() {
      this.$emit("close");
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      this.lib = true;
      this.ref_code = true;
      //this.$refs.form.resetValidation();
      if (this.$refs.form.validate()) {
        let ok = true;
        if (this.parametre == 5) ok = await this.label_valid();
        // a faire

        if (ok) {
          this.progress = true;
          if (this.editedItem.id > 0) {
            let v = {
              productarticle: {
                id: this.editedItem.id,
                tier_id: this.editedItem.tier_id,
                produit_id: this.editedItem.produit_id,
                prixachat: parseFloat(this.editedItem.prixachat),
                label_ext: this.editedItem.label_ext
                  ? this.editedItem.label_ext
                  : null,
                ref_ext: this.editedItem.ref_ext
                  ? this.editedItem.ref_ext
                  : null,
                delai: this.editedItem.delai,
                qualite: this.editedItem.qualite,
                statut_id: this.editedItem.statut_id,
                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(
              this.parametre == 1 || this.parametre == 4
                ? UPDATE_PC
                : UPDATE_PRODUCT_ARTICLE,
              v
            );

            this.progress = false;
            this.items.splice(this.editedItem.index, 1, this.editedItem);
            this.close();
          } else {
            if (this.tier_type == "0") {
              this.editedItem.statuts_name = "Actif";
              this.editedItem.color = "green";
              this.editedItem.statut_id = 2;
              let v = {
                productarticle: {
                  prixachat: parseFloat(this.editedItem.prixachat),
                  tier_id: this.editedItem.tier_id,
                  produit_id: this.editedItem.produit_id,
                  label_ext: this.editedItem.label_ext
                    ? this.editedItem.label_ext
                    : null,
                  ref_ext: this.editedItem.ref_ext
                    ? this.editedItem.ref_ext
                    : null,
                  delai: this.editedItem.delai,
                  qualite: this.editedItem.qualite,
                  statut_id: this.editedItem.statut_id,
                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              };
              let r = await this.maj(
                this.parametre == 1 || this.parametre == 4
                  ? CREATE_PC
                  : CREATE_PRODUCT_ARTICLE,
                v
              );
              if (this.parametre == 3) this.editedItem.id = r.createTierPrix.id;
              else if (this.parametre == 1 || this.parametre == 4)
                this.editedItem.id = r.createProductClient.id;
              else this.editedItem.id = r.createProductArticle.id;
              this.editedItem.code =
                (this.parametre == 2
                  ? this.product.code
                  : this.editedItem.code) +
                "-" +
                this.editedItem.id;
              if (this.items) this.items.push(this.editedItem);
              this.progress = false;
            } else {
              if (
                await this.$refs.confirm.open(
                  "Confirmation",
                  "Etês-vous sûr ? "
                )
              ) {
                if (this.parametre == 2) {
                  //all tier of categorie
                  let v = {
                    produit_id: this.editedItem.produit_id,
                    cat_list: this.editedItem.categories,
                    create_uid: this.$store.state.me.id,
                  };
                  let r = await this.maj(INSERT_ARTICLE_PRODUCT_CAT, v, true);
                  if (r) {
                    let nb = r.InsertArticleByCatProduct.length;
                    if (nb > 0) {
                      this.snackbar_text =
                        nb + " fournisseurs ajoutés avec succes";
                      this.snackbar_color = "success";
                      this.snackbar = true;
                      this.$emit("refresh");
                    } else {
                      this.snackbar_text = "Aucun fournisseur ajouté";
                      this.snackbar_color = "error";
                      this.snackbar = true;
                    }
                  }
                }
                //all product of this categorie
                if (this.parametre == 5) {
                  let v = {
                    tier_id: this.editedItem.tier_id,
                    cat_list: this.editedItem.categories,
                    create_uid: this.$store.state.me.id,
                  };
                  let r = await this.maj(INSERT_ARTICLE_TIER_CAT, v, true);
                  if (r) {
                    let nb = r.InsertArticleByCatTier.length;
                    if (nb > 0) {
                      this.snackbar_text = nb + " Articles ajoutés avec succes";
                      this.snackbar_color = "success";
                      this.snackbar = true;
                      this.$emit("refresh");
                    } else {
                      this.snackbar_text = "Aucun article ajouté";
                      this.snackbar_color = "error";
                      this.snackbar = true;
                    }
                  }
                }
              }
            }
            this.close();
          }
        }
      }
    },
  },
};
</script>
